









import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Flatpickr from "flatpickr";
import "flatpickr/dist/themes/airbnb.css";
//@ts-ignore
import FlatpickrShortcuts from "shortcut-buttons-flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
import i18n from "@/i18n";
//@ts-ignore
import Cleave from "cleave.js";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Validation from "@/components/Validation.vue";
import moment from "moment";

@Component({
  components: { Validation },
})
export default class Datepicker extends Vue {
  public name = "Datepicker";

  $refs!: {
    datePicker: HTMLFormElement;
  };

  @Prop({
    required: false,
    validator(value) {
      return (
        value === null || value instanceof Date || typeof value === "string" || value instanceof String || value instanceof Array || typeof value === "number"
      );
    },
  })
  public value!: any;

  @Prop({ required: false, default: () => false })
  public disabled!: any;

  @Prop() initialDate!: string;

  @Prop({
    type: String,
    default: () => {
      return i18n.t("calendar.label_no_date");
    },
  })
  public placeholder!: string;

  @Prop({
    type: Number,
    default: () => 1,
  })
  public hourIncrement!: number;

  @Prop({
    type: Number,
    default: () => 5,
  })
  public minuteIncrement!: number;

  @Prop({
    type: String,
    default: () => "Y-m-d",
  })
  public dateFormat!: string;

  @Prop({
    type: String,
    default: () => "d.m.Y",
  })
  public altFormat!: string;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public twelveHourTime!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public enableTime!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public enableSeconds!: boolean;

  @Prop({
    type: Number,
    default: () => 1,
  })
  public firstDayOfWeek!: number;

  @Prop({
    type: [Object, String],
    default: () => "",
  })
  public inputClass!: any;

  @Prop({
    type: [Boolean],
    default: () => false,
  })
  public onlyMonth!: boolean;

  @Prop({
    type: [Boolean],
    default: () => false,
  })
  public onlyYear!: boolean;

  @Prop({
    type: [String],
  })
  public minDate?: string;

  @Prop({
    type: [String],
  })
  public maxDate?: string;

  public flatpickr: any | null = null;
  public iconPrefix = "fat";

  public cleave = null;

  public mounted() {
    this.$nextTick(() => {
      this.createFlatpickr();
      this.createCleave();
    });
  }

  private createCleave() {
    const el = this.$el.getElementsByClassName("flatpickr-input")[1];

    const cleaveOptions = {
      date: true,
      delimiter: ".",
      datePattern: ["d", "m", "Y"],
      dateMin: this.minDateFormat || "1900-01-01",
      dateMax: this.maxDateFormat || "2100-01-01",
    };

    if (this.onlyMonth) {
      Object.assign(cleaveOptions, {
        datePattern: ["m"],
      });
    } else if (this.onlyYear) {
      Object.assign(cleaveOptions, {
        datePattern: ["Y"],
      });
    }
    this.cleave = new Cleave(el, cleaveOptions);
  }

  public get getRef(): any {
    return this.$el.querySelector("#flatpickr");
  }

  public createFlatpickr(): void {
    Flatpickr.localize(German);

    const plugins = [];

    if (this.onlyMonth || this.onlyYear) {
      plugins.push(
        MonthSelectPlugin({
          shorthand: true,
          dateFormat: this.onlyMonth ? "m" : "Y",
          altFormat: this.onlyMonth ? "m" : "Y",
        })
      );
    } else {
      plugins.push(
        new FlatpickrShortcuts({
          button: [{ label: this.$t("calendar.today") }],
          onClick(index: number, fp: any) {
            fp.setDate(new Date());
          },
        })
      );
    }

    this.flatpickr = Flatpickr(this.getRef, {
      enableTime: this.enableTime,
      enableSeconds: this.enableSeconds,
      onOpen: this.onOpen,
      onClose: this.onClose,
      onChange: this.onChange,
      dateFormat: this.dateFormat,
      altInput: true,
      altFormat: this.altFormat,
      allowInput: true,
      time_24hr: !this.twelveHourTime,
      hourIncrement: this.hourIncrement,
      minuteIncrement: this.minuteIncrement,
      locale: { firstDayOfWeek: this.firstDayOfWeek },
      clickOpens: false,
      altInputClass: "flatpickr-input",
      plugins: plugins,
      disableMobile: true,
      minDate: this.minDateFormat,
      maxDate: this.maxDateFormat,
      onReady: (_, __, fp) => {
        if (this.onlyMonth) {
          fp.calendarContainer.classList.add("only-month");
        } else if (this.onlyYear) {
          fp.calendarContainer.classList.add("only-year");
        }

        if (!this.value && this.initialDate) {
          fp.setDate(this.initialDate);
          this.onChange(null, this.initialDate);
        }

      },
      onYearChange: (val: any, val2: any, instance: any) => {
        if (this.onlyYear) {
          instance.setDate(new Date(instance.currentYear, 1));
        }
      },
    });

    if (this.value) {
      this.flatpickr.setDate(this.value);
    }

    if (this.disabled) {
      this.flatpickr.altInput.setAttribute("disabled", String(true));
    }
  }

  public beforeDestroy() {
    if (this.flatpickr) {
      this.flatpickr.destroy();
    }

    if (this.cleave) {
      //@ts-ignore
      this.cleave.destroy();
    }
  }

  public onOpen(selectedDates: any, dateStr: any, instance: any) {
    if (this.onlyYear && !this.value) {
      instance.setDate(new Date());
    }
  }

  public onClose(event: any, dateStr: string) {
    this.onChange(event, dateStr);
  }

  public onChange(event: any, dateStr: string) {
    this.$emit("input", dateStr ? dateStr : null);
  }

  public clear() {
    this.flatpickr.clear();
  }

  @Watch("value")
  public onChangeValue(newValue: any): void {
    if (this.flatpickr) {
      this.flatpickr.setDate(newValue);
    }
  }

  @Watch("minDate")
  public onMinDateValue(): void {
    if (this.flatpickr) {
      this.flatpickr.set("minDate", this.minDateFormat);
      this.createCleave();
    }
  }

  @Watch("maxDate")
  public onMaxDateValue(): void {
    if (this.flatpickr) {
      this.flatpickr.set("maxDate", this.maxDateFormat);
      this.createCleave();
    }
  }

  @Watch("disabled")
  public onChangeDisabled(newValue: any): void {
    if (newValue) {
      this.flatpickr.altInput.setAttribute("disabled", newValue);
    } else {
      this.flatpickr.altInput.removeAttribute("disabled");
    }
  }

  protected onClick(event: Event): void {
    event.preventDefault();
    if (this.flatpickr) {
      this.flatpickr.toggle();
    }
  }

  protected mouseOver(): void {
    this.iconPrefix = "fas";
  }

  protected mouseLeave(): void {
    this.iconPrefix = "fat";
  }

  private get minDateFormat() {
    const date = moment(this.minDate, "DD.MM.YYYY");
    return date.isValid() ? date.format("YYYY-MM-DD") : undefined;
  }

  private get maxDateFormat() {
    const date = moment(this.maxDate, "DD.MM.YYYY");
    return date.isValid() ? date.format("YYYY-MM-DD") : undefined;
  }
}
